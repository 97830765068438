import React from 'react';
import PropTypes from 'prop-types';
import { default as cx } from 'clsx';

import Content from 'atoms/content/Content';
import { default as ImageAtom } from 'atoms/image/Image';

import './Image.scss';

const defaultProps = {
    caption: [],
    className: '',
    image: {},
    size: 'Normal',
};

const propTypes = {
    caption: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        dimensions: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
        }),
        url: PropTypes.string,
    }),
    size: PropTypes.string,
};

function Image({
    caption,
    className,
    image,
    size,
}) {
    const sizeClassName = (function(size) {
        switch(size) {
            case 'Normal':
                return '';
            case 'Wide':
                return 'o-image--wide';
            case 'Full Width':
                return 'o-image--xwide';
            default:
                return '';
        }
    })(size);
    const imageSize = (function(size) {
        switch(size) {
            case 'Normal':
                return '';
            case 'Wide':
                return 'xlarge';
            case 'Full Width':
                return 'xlarge';
            default:
                return '';
        }
    })(size);
    const classNames = cx('o-image', sizeClassName, className);
    const imageAlt = (image.alt) ? image.alt : '';
    const imageHeight = image?.dimensions?.height;
    const imageUrl = image?.url;
    const imageWidth = image?.dimensions?.width;

    return (
        <>
            <div className={classNames}>
                <div className="l-grid l-grid--container">
                    <figure className="o-image__container">
                        {imageUrl && (
                            <ImageAtom
                                className="o-image__image"
                                image={{
                                    alt: imageAlt,
                                    dimensions: {
                                        height: imageHeight,
                                        width: imageWidth,
                                    },
                                    url: imageUrl,
                                }}
                                size={imageSize}
                            />
                        )}
                        {caption && (
                            <figcaption className="o-image__caption">
                                <Content content={caption} />
                            </figcaption>
                        )}
                    </figure>
                </div>
            </div>
        </>
    );
}

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;