import React from 'react';
import PropTypes from 'prop-types';

import { default as ButtonAtom } from 'atoms/button/Button';

import './Button.scss';

const defaultProps = {
    buttonStyle: '',
    className: '',
    link: {},
    linkText: '',
};

const propTypes = {
    buttonStyle: PropTypes.string,
    className: PropTypes.string,
    link: PropTypes.object,
    linkText: PropTypes.string,
};

function Button({
    buttonStyle,
    className,
    link,
    linkText,
}) {
    const buttonStyleClassName = (function(buttonStyle) {
        switch(buttonStyle) {
            case 'Primary':
                return '';
            case 'Secondary':
                return 'a-button--secondary';
            default:
                return '';
        }
    })(buttonStyle);
    const linkUrl = link;

    return (
        <>
            <div className={`o-button ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-button__container">
                        <ButtonAtom className={`${buttonStyleClassName} o-button__button`} to={linkUrl}>{linkText}</ButtonAtom>
                    </div>
                </div>
            </div>
        </>
    );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
