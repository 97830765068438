import React from 'react';
import PropTypes from 'prop-types';

import { default as ContentAtom } from 'atoms/content/Content';

import './Content.scss';

const defaultProps = {
    className: '',
    content: [],
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
};

function Content({
    className,
    content,
}) {
    return (
        <>
            <div className={`o-content ${className}`}>
                <div className="l-grid l-grid--container">
                    <div className="o-content__container">
                        <ContentAtom className="o-content__content" content={content} />
                    </div>
                </div>
            </div>
        </>
    );
}

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;