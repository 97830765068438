import React from 'react';
import PropTypes from 'prop-types';
import { default as cx } from 'clsx';

import Content from 'atoms/content/Content';
import VideoAtom from 'atoms/video/Video';

import './Video.scss';

const defaultProps = {
    caption: [],
    className: '',
    size: 'Normal',
    videoEmbed: {},
};

const propTypes = {
    caption: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    size: PropTypes.string,
    videoEmbed: PropTypes.shape({
        html: PropTypes.string,
    }),
};

function Video({
    caption,
    className,
    size,
    videoEmbed,
}) {
    const sizeClassName = (function(size) {
        switch(size) {
            case 'Normal':
                return '';
            case 'Wide':
                return 'o-video--wide';
            case 'Full Width':
                return 'o-video--xwide';
            default:
                return '';
        }
    })(size);
    const classNames = cx('o-video', sizeClassName, className);
    const videoEmbedUrl = videoEmbed?.html;

    return (
        <>
            <div className={classNames}>
                <div className="l-grid l-grid--container">
                    <figure className="o-video__container">
                        {videoEmbedUrl && (
                            <VideoAtom
                                className="o-video__video"
                                videoEmbed={videoEmbed}
                            />
                        )}
                        {caption && (
                            <figcaption className="o-video__caption">
                                <Content content={caption} />
                            </figcaption>
                        )}
                    </figure>
                </div>
            </div>
        </>
    );
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;